import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'
import axios from "axios";
import { authHeader } from '@/helpers/authservice/auth-header';
import router from "@/router";

Vue.use(Vuex)

const state = {
    api: process.env.VUE_APP_API,
    tokenKey: null
};
const getters = {
    StateApi: state => state.api,
};
const actions = {
    async getChats({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + '/chats', {headers: authHeader()})
            .then(async function (response) {
                res.status = response.data.status
                res.data = response.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))

                res.data = error.response.data.status_description
                res.status = error.response.data.status

                if (res.status === 401) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getProfiles({commit, getters}) {
        let res = {}
        await axios.get(getters.StateApi + '/profiles', {headers: authHeader()})
            .then(async function (response) {
                res.status = response.data.status
                res.data = response.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))

                res.data = error.response.data.status_description
                res.status = error.response.data.status

                if (res.status === 401) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getProfile({commit, getters}, payload) {
        let res = {}
        await axios.get(getters.StateApi + '/profile/'+payload.id, {headers: authHeader()})
            .then(async function (response) {
                res.status = response.data.status
                res.data = response.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))

                res.data = error.response.data.status_description
                res.status = error.response.data.status

                if (res.status === 401) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async getMessages({commit, getters}, payload) {
        let res = {}
        await axios.get(getters.StateApi + '/get_message/'+payload.id, {headers: authHeader()})
            .then(async function (response) {
                res.status = response.data.status
                res.data = response.data.data
            })
            .catch(function(error) {
                console.log(JSON.stringify(error.response))

                res.data = error.response.data.status_description
                res.status = error.response.data.status

                if (res.status === 401) {
                    commit('setLogOut')
                }
            })

        return res
    },
    async sendText({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + '/send/message', payload, {headers: authHeader()})
            .then(async function (response) {
                res.status = response.data.status
                res.data = response.data.data
            })
            .catch(async function (error) {
                console.log(JSON.stringify(error.response))

                res.data = error.response.data.status_description
                res.status = error.response.data.status

                if (res.status === 401) {
                    commit('setLogOut')
                }
            })

        return res
    },
}

const mutations = {
    setLogOut(state){
        state.tokenKey = null
        localStorage.removeItem('user')
        router.push({name: 'login'});
    },
}

const store = new Vuex.Store({
    modules,
    state () {
        return state
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
})

export default store

